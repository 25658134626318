'use strict';

import {updatePricebox} from "./updatePricebox";
import {updateQtyInput} from "./updateQtyInput";
import {updateFormItemID, updateFormVariantCode, updateOrderButton} from "./updateOrderbutton";
import {sendToDataLayer} from "./itemcardDataLayer";
import {updateTeamorderTable} from "./teamorderTable";
import {toggleAvailabilityElement, updateAvailabilityBox} from "./updateAvailability";

const selectionTeamOrder = document.querySelector(".productPageSideOrderTableSelect--teamorder");
const selectionSingle = document.querySelector(".productPageSideOrderTableSelect--single");
export const inputHelperTeamOrderSizeSelected = document.getElementById("teamOrderSizeSelected");

function getActiveTab(value, tabs, tabsContent) {
    for (let tab of tabs) {
        if (tab.dataset.value === value) {
            tab.classList.add("is-active");
        } else {
            tab.classList.remove("is-active");
        }
    }
    for (let tabContent of tabsContent) {
        if (tabContent.dataset.value === value) {
            tabContent.classList.add("is-active");
        } else {
            tabContent.classList.remove("is-active");
        }
    }
}

function isScrollable(element) {
    return element.scrollWidth > element.clientWidth;
}

function scrollToActiveTab(tab){
    const parent = tab.parentElement;
    const parentWidth = parent.offsetWidth;
    const itemWidth = tab.offsetWidth;
    const itemLeft = tab.offsetLeft;
    const scrollPosition = itemLeft - (parentWidth / 2) + (itemWidth / 2);

    parent.scroll({
        left: scrollPosition,
        behavior: 'smooth'
    });
}

function createTabSelectionEventlistener(doc = document) {
    for (let customSelect of doc.querySelectorAll(".productPageSideOrderTableSelect__customSelect")) {
        let tabsWrapper = customSelect.querySelector('.productPageSideOrderTableSelect__customSelectTabHeader > ul');
        let tabs = tabsWrapper.querySelectorAll('li');
        let tabsContent = customSelect.querySelectorAll('.productPageSideOrderTableSelect__customSelectTabContent > ul');
        if (tabs && tabsContent) {
            for (let tab of tabs) {
                tab.addEventListener("click", function () {
                    getActiveTab(tab.dataset.value, tabs, tabsContent);
                    scrollToActiveTab(tab);
                });
            }
            for (let content of tabsContent) {
                attachTabSelectionListeners(content, doc)
            }
        }
    }
};

function updateSingleCustomSelectLabel(selectedValue = "") {
    const singleSelect = document.getElementById('productPageOrderTableCustomSelectSingleSpan');
    if(singleSelect){
        let value = singleSelect.dataset.defaultLabel;

        if(selectedValue !== ""){
            value = selectedValue;
        }

        singleSelect.innerText = value;
        singleSelect.dataset.value = value;
    }
}

function updateTeamorderCustomSelectLabel(qty = 0){
    const customSelectTeamSpan = document.getElementById("productPageOrderTableCustomSelectTeamSpan");
    if(customSelectTeamSpan){
        let label = customSelectTeamSpan.dataset.defaultLabel;
        if(qty > 1){
            label = customSelectTeamSpan.dataset.selectedMultipleLabel;
        }else if(qty === 1){
            label = customSelectTeamSpan.dataset.selectedSingleLabel;
        }
        customSelectTeamSpan.innerText = label.replace("%qty%",qty);
    }
}

function updateTeamorderCustomSelect(qtyInputs = document.querySelectorAll(".productPageSideOrderTableSelect__customSelect .productPageQuantity__numberField")){
    let sumQty = 0;
    if(qtyInputs){
        for(let input of qtyInputs){
            sumQty = parseInt(input.value) + sumQty;
        }
        updateTeamorderTable(qtyInputs);
    }
    updateTeamorderCustomSelectLabel(sumQty);

    inputHelperTeamOrderSizeSelected.value = sumQty;
    updateOrderButton();
}

function createTeamOrderQtyListener(doc = document){
    const wrapper = doc.querySelector('.productPageSideOrderTableSelect__customSelect--teamorder');

    if(wrapper){
        const qtyInputs = wrapper.querySelectorAll(".productPageQuantity__numberField");
        const qtySpinners = wrapper.querySelectorAll(".js-spinnerUp,.js-spinnerDown");

        if(qtyInputs){
            for(let input of qtyInputs){
                input.addEventListener("keyup",function (){
                    setTimeout(function (){
                        updateTeamorderCustomSelect(qtyInputs);
                    },200);
                });
            }
        }

        if(qtySpinners){
            for(let qtySpinner of qtySpinners){
                qtySpinner.addEventListener("click",function (){
                    setTimeout(function (){
                        updateTeamorderCustomSelect(qtyInputs);
                    },200);
                });
            }
        }
    }
}

function loadSelection(func, callback = function () {
}, callbackError = function () {
}) {
    fetch('/module/dcshop/ajax/itemcardAjax.php?function=' + func, {
        method: 'POST'
    }).then(function (response) {
        return response.text();
    }).then(function (html) {
        var parser = new DOMParser();
        var doc = parser.parseFromString(html, 'text/html');
        let select = doc.querySelector('.productPageSideOrderTableSelect__customSelect');
        if (select) {
            callback(select);
        }
    }).catch(function (err) {
        console.error(err);
        callbackError(err);
    });
}

function hideCustomSelectElements() {
    toggleClassForElements(".productPageSideOrderTableSelect__selectBox", [], ["is-active"]);
    toggleClassForElements(".productPageSideOrderTableSelect__background", ['d-none']);
    for (let select of document.querySelectorAll(".productPageSideOrderTableSelect__customSelect")) {
        select.style.display = "none";
    }
}

function showSatelliteBubble(){
    const satelliteBubble = document.querySelector(".satellite-bubble");
    if(satelliteBubble){
        satelliteBubble.style.display = "";
    }
}

function hideSatelliteBubble(){
    const satelliteBubble = document.querySelector(".satellite-bubble");
    if(satelliteBubble){
        satelliteBubble.style.display = "none";
    }
}

function toggleSizeSelect(select,selection){
    document.body.classList.remove("productPageSizeIsOpen");
    showSatelliteBubble();
    if (select.classList.contains("is-active")) {
        if (selection) {
            selection.style.display = "none";
        }
        select.classList.remove("is-active");
    } else {
        if (selection) {
            selection.style.display = "block";
        }
        select.classList.add("is-active");
        document.body.classList.add("productPageSizeIsOpen");

        let tabsWrapperUl = selection.querySelector(".productPageSideOrderTableSelect__customSelectTabHeader > ul");

        if(tabsWrapperUl){
            if(isScrollable(tabsWrapperUl)){
                tabsWrapperUl.closest(".productPageSideOrderTableSelect__customSelectTabHeader").classList.add("is-scrollable");
            }else{
                tabsWrapperUl.closest(".productPageSideOrderTableSelect__customSelectTabHeader").classList.remove("is-scrollable");
            }
        }

        hideSatelliteBubble();
    }
}

function createSelectEventListener() {
    document.addEventListener('click', (event) => {
        const target = event.target;
        if (!target.closest(".productPageSideOrderTableSelect")) {
            hideCustomSelectElements();
        }
    });

    for (let select of document.querySelectorAll('.js-loadProductPageSelection')) {
        let wrapper = select.closest(".productPageSideOrderTableSelect");
        if (wrapper) {
            let selection = wrapper.querySelector('.productPageSideOrderTableSelect__customSelect');
            if(selection){
                select.addEventListener("click", () => {
                    toggleSizeSelect(select,selection);
                });
                let closeButton = wrapper.querySelector('.productPageSideOrderTableSelect__customSelectClose');
                if(closeButton){
                    closeButton.addEventListener("click", () => {
                        toggleSizeSelect(select,selection);
                    });
                }
            }
        }
    }
}

function toggleClassForElements(selector, addClasses = [], removeClasses = []) {
    document.querySelectorAll(selector).forEach(element => {
        addClasses.forEach(cls => element.classList.add(cls));
        removeClasses.forEach(cls => element.classList.remove(cls));
    });
}

function onClickEventlistenerForListItem() {
    const orderTypeCloseOnSelect = ['single'];
    for (let orderType of orderTypeCloseOnSelect) {
        const divElements = document.querySelectorAll(`div[id*="${orderType}Order_"]`);

        divElements.forEach(divElement => {
            const liElements = divElement.querySelectorAll('li');

            liElements.forEach(li => {
                li.addEventListener('click', () => {
                    toggleCustomSelect("");
                });
            });
        });
    }
}

function showTeamOrderSelection(){
    selectionTeamOrder.classList.remove("d-none");
    selectionSingle.classList.add("d-none");
}

function showSingleOrderSelection(){
    selectionTeamOrder.classList.add("d-none");
    selectionSingle.classList.remove("d-none");
}

function checkActiveSelection(){
    let switchTeam = document.querySelector(".js-switchProductPageSwitch");

    if(switchTeam.checked){
        inputHelperTeamOrderSizeSelected.value = 0;
        showTeamOrderSelection();
        toggleAvailabilityElement(true);
    }else{
        showSingleOrderSelection();
    }
    updateOrderButton();
}

function createTeamOrderSwitchEventListener() {
    let switchTeam = document.querySelector(".js-switchProductPageSwitch");
    if (switchTeam) {
        checkActiveSelection();
        switchTeam.addEventListener("change", () => {
            let wrapper = switchTeam.closest('.productPageSideOrderTableSwitch__left__switch');
            if(wrapper){
                if(wrapper.classList.contains("active")){
                    wrapper.classList.remove("active");
                }else{
                    wrapper.classList.add("active");
                    updateTeamorderCustomSelect();
                }
                checkActiveSelection();
            }
        });
    }
}

function activateItem(item) {
    for (let i of document.querySelectorAll(".productPageSideOrderTableSelect__customSelectTabContent li ")) {
        if (i === item) {
            i.classList.add("is-active");
        } else {
            i.classList.remove("is-active");
        }
    }
}

function updateViews(item){
    if(item){
        activateItem(item);
        updatePricebox(item.dataset);
        updateAvailabilityBox(item.dataset);

        let maxQty = false;
        let steps = false;
        if (item.dataset.maxQty) {
            maxQty = item.dataset.maxQty;
        }
        if (item.dataset.steps) {
            steps = item.dataset.steps;
        }
        updateQtyInput(maxQty, steps);

        updateFormVariantCode(item.dataset.variantCode);
        updateFormItemID(item.dataset.itemId)

        let orderboxWrapper = document.querySelector(".productPageOrderboxWrapper");
        let orderbox = orderboxWrapper.querySelector(".productPageOrderBox");
        let orderboxNotification = orderboxWrapper.querySelector(".productPageOrderboxNotification");

        if (orderbox) {
            orderbox.style.display = "";
        }

        if (orderboxNotification) {
            orderboxNotification.style.display = "none";
        }

        if (item.classList.contains("unavailable")) {
            if (orderbox) {
                orderbox.style.display = "none";
            }

            if (orderboxNotification) {
                orderboxNotification.style.display = "";
            }
        } else {
            if (item.dataset.variantCode && item.dataset.price) {
                sendToDataLayer({
                    "var_code_2": item.dataset.variantCode,
                    "price": item.dataset.price
                });
            }
        }

        updateOrderButton();
    }
}

function attachTabSelectionListeners(content) {
    content.querySelectorAll('li').forEach(item =>
        item.addEventListener('click', event => {
            if(!event.target.closest(".productPageSideOrderTableSelect__customSelect--teamorder")){
                updateSingleCustomSelectLabel(event.currentTarget.dataset.value);
                hideCustomSelectElements();
                updateViews(item);
            }
            document.body.classList.remove("productPageSizeIsOpen");
        })
    );
}

export function createTeamOrderEventListener() {
    createSelectEventListener();
    createTabSelectionEventlistener();
    createTeamOrderSwitchEventListener();
    onClickEventlistenerForListItem();
    createTeamOrderQtyListener();

    let sizes = document.querySelectorAll('.productPageSideOrderTableSelect__customSelect--single .productPageSideOrderTableSelect__customSelectTabContent > ul > li');
    if(sizes){
        if(sizes.length === 1){
            updateViews(sizes[0]);
            updateSingleCustomSelectLabel(sizes[0].dataset.value);
        }
    }
}