import {updateFormQty} from "./updateOrderbutton";

export function updateQtyInput(maxQty = false,step = false){
    const input = document.getElementById("productPageQuantitySingle");

    if(input){
        input.value = 1;
        updateFormQty(input.value);

        if(maxQty !== false){
            input.setAttribute("max",maxQty);
        }else{
            input.removeAttribute("max");
        }
        if(step !== false){
            input.setAttribute("step",step);
        }else{
            input.removeAttribute("step");
        }
    }
}