
export function sendToDataLayer(newdataLayerItemDetails = {}) {
    if (dataLayerItemDetails !== undefined) {
        const dataDetails = {...dataLayerItemDetails, ...newdataLayerItemDetails};

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'itemDetail',
            'ecommerce': {
                'detail': {
                    'products': [{
                        'name': dataDetails["name"],
                        'id': dataDetails["item_no_2"],
                        'id-var': dataDetails["item_no_2"] + "-" + dataDetails["var_code_2"],
                        'price': dataDetails["price"],
                        'brand': dataDetails["brand"],
                        'category': dataDetails["category"],
                        'variant': dataDetails["variant"]
                    }]
                }
            },
            "google_tag_params": {
                "ecomm_pagetype": "product",
                "ecomm_prodid": dataDetails["item_no_2"] + "-" + dataDetails["var_code_2"]
            }
        });
    } else {
        console.error("dataLayerItemDetails is not defined");
    }
}