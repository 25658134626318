function clearTeamorderTable(table){
    for(let tr of table.querySelectorAll("tr:not(.productPageOrderTeamTable__header)")){
        tr.remove();
    }
}

function generateTeamorderTableRow(table,value,qty){
    const row = document.createElement("tr");
    row.innerHTML = "<td class=\"productPageOrderTeamTable__value\">"+value+"</td><td class=\"productPageOrderTeamTable__qty\">"+qty+"</td>";
    table.appendChild(row);
}

export function checkTeamorderTableSelected(){
    for(let input of document.querySelectorAll(".productPageSideOrderTableSelect__customSelect .productPageQuantity__numberField")){
        if(parseInt(input.value) > 0){
            return true;
        }
    }
    return false;
}

export function updateTeamorderTable(inputs){
    const teamorderTable = document.getElementById("productPageOrderTeamTable");

    if(teamorderTable){
        clearTeamorderTable(teamorderTable);
        teamorderTable.style.display = "none";
        if(inputs){
            for(let i of inputs){
                if(parseInt(i.value) > 0){
                    let li = i.closest("li");
                    if(li){
                        generateTeamorderTableRow(teamorderTable,li.dataset.value,i.value);
                        teamorderTable.style.display = "";
                    }
                }
            }
        }
    }
}