import Swiper from "swiper";
import {loadImage} from "./loadItemcardGalleryImages";
import {Navigation, Pagination} from 'swiper/modules';

function getActiveIndex(items){
    let index = 0;
    for(let i of items){
        if(i.classList.contains("is-active")){
            break;
        }
        index++;
    }

    if(index > items.length){
        index = 0;
    }

    return index;
}

function checkImageIsLoaded(variantHover,src){
    const img = new Image();
    img.onload = function (){
        variantHover.classList.add("is-loaded");
    };
    img.onerror = function (){
        variantHover.classList.remove("is-loaded");
    };
    img.src = src;
}

function showVariantHoverImage(hoverSrc){
    if(hoverSrc){
        const variantHover = document.querySelector('.variantHover');
        if(variantHover){
            variantHover.classList.remove("is-loaded");
            const variantHoverImg = variantHover.querySelector("img");
            if(variantHoverImg){
                variantHoverImg.src = hoverSrc;
                checkImageIsLoaded(variantHover,hoverSrc);
            }
        }
    }
}

function hideVariantHoverImage(){
    const variantHover = document.querySelector('.variantHover');
    if(variantHover){
        variantHover.classList.remove("is-loaded");
    }
}

function switchColorName(altTag = ""){
    let colorName = document.querySelector('.js-itemcardColorName');
    if(colorName){
        if(altTag !== ""){
            if(colorName.getAttribute("data-original-text") === null){
                colorName.setAttribute("data-original-text",colorName.innerText);
            }
            colorName.innerText = altTag;
        }else{
            if(colorName.dataset.originalText){
                colorName.innerText = colorName.dataset.originalText;
            }
        }
    }
}

export function variantSlider(productPage){
    for(let slider of productPage.querySelectorAll('.productPageVariants')){
        let initialSlide = 0;
        let items = slider.querySelectorAll('.productPageVariants__item');
        if(items){
            initialSlide = getActiveIndex(items);
        }
        Swiper.use([Pagination, Navigation]);
        const swiper = new Swiper(slider, {
            slidesPerView: "auto",
            spaceBetween: 8,
            initialSlide: initialSlide,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });

        if(window.innerWidth >= 1024){
            slider.addEventListener("mouseover",function (e){
                if(e.target.closest('.productPageVariants__item') && e.target.nodeName === "IMG"){
                    showVariantHoverImage(e.target.dataset.hoverSrc);
                    let altTag = e.target.getAttribute('alt');
                    if(altTag){
                        switchColorName(altTag);
                    }
                }
            });
            slider.addEventListener("mouseout",function (){
                hideVariantHoverImage();
                switchColorName();
            });
        }
    }
}