'use strict';

export function updateAvailabilityBox(dataset){
    const availabilityWrapper = document.querySelector("#productPage .js-inventory--default");
    if(availabilityWrapper && dataset.availabilityHtml){
        availabilityWrapper.outerHTML = dataset.availabilityHtml;
    }
}

export function toggleAvailabilityElement(hide){
    const availabilityWrapper = document.querySelector("#productPage .js-inventory--default");
    if(availabilityWrapper) {
        if(hide) {
            availabilityWrapper.style.display = 'none';
        }
        else {
            availabilityWrapper.style.display = '';
        }
    }
}