
export function loadImage(img){
    if(img.dataset.src !== undefined && !img.classList.contains("img-loaded")) {
        let pseudoimg = document.createElement('img');
        pseudoimg.src = img.dataset.src;
        pseudoimg.onload = function () {
            img.src = pseudoimg.src;
            img.classList.add("img-loaded");
        };
    }
}

function loadItemcardGalleryImagesByVisible(grid,images){
    for(let img of images){
        let canLoadImage = true;
        if(img.getBoundingClientRect().x > (grid.offsetWidth*1.5)){
            canLoadImage = false;
        }
        if(canLoadImage){
            loadImage(img);
        }
    }
}

export function loadItemcardGalleryImages(galleryImages){
    for (let galleryImg of galleryImages){
        loadImage(galleryImg);
    }
}

export function loadItemcardGalleryImagesByScrolling(grid){
    const images = grid.querySelectorAll('.productPageImagesGrid__item img');
    if(images){
        loadItemcardGalleryImagesByVisible(grid,images);

        grid.addEventListener("scroll",function (){
            loadItemcardGalleryImagesByVisible(grid,images);
        });
    }
}