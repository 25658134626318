function fillPrices(prices,templatePricebox){
    const crossPriceHTML = templatePricebox.querySelector(".cross_price");
    const actionPriceHTML = templatePricebox.querySelector(".action_price");
    const basePriceHTML = templatePricebox.querySelector(".base_price");
    const discountPriceHTML = templatePricebox.querySelector(".discount_price");

    if(prices.crossPrice){
        crossPriceHTML.innerHTML = prices.crossPrice;
    }else{
        crossPriceHTML.remove();
    }

    if(prices.actionPrice){
        actionPriceHTML.innerHTML = prices.actionPrice;
    }else{
        actionPriceHTML.remove();
    }

    if(prices.basePrice){
        basePriceHTML.innerHTML = prices.basePrice;
    }else{
        basePriceHTML.remove();
    }

    if(prices.discountPrice){
        discountPriceHTML.innerHTML = prices.discountPrice;
    }else{
        discountPriceHTML.remove();
    }
}

export function updatePricebox(prices){
    let pricesWrapper = document.querySelector("#productPage .pricesWrapper");

    if(pricesWrapper){
        const templatePricebox = document.getElementById("itemcardTemplatePriceBox");
        const clone = templatePricebox.content.cloneNode(true);
        if(clone){
            fillPrices(prices,clone);
            let oldPriceBox = pricesWrapper.querySelector(".item_prices");
            if(oldPriceBox){
                oldPriceBox.remove();
            }
            pricesWrapper.prepend(clone);
        }
    }
}