import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules';
import anime from "animejs";

export function openFullscreenGallery(clickedElement, selectorImg = ".js-openGallery") {

    const navigation = true;
    const pagination = true;

    function buildSwiperWrapper(medias, navigation = false,pagination = false) {
        const swiperWrapper = document.createElement('div');
        swiperWrapper.classList.add('swiper-wrapper');

        for (let media of medias) {
            const mediaSlide = document.createElement('div');
            mediaSlide.classList.add('swiper-slide');
            switch (media.type) {
                case "video":
                case "embed":
                    mediaSlide.innerHTML = media.src;
                    break;
                case "image":
                    mediaSlide.innerHTML = "<img src='" + media.src + "' alt='" + media.src + "'/>";
                    break;
            }
            swiperWrapper.appendChild(mediaSlide);
        }

        return swiperWrapper;
    }

    function buildNavigation(){
        //build prev and next button
        const prevBtn = document.createElement('div');
        prevBtn.classList.add('swiper-button-prev');
        prevBtn.innerHTML = "<i class=\"icon icon-angle-left\"></i>";

        const nextBtn = document.createElement('div');
        nextBtn.classList.add('swiper-button-next');
        nextBtn.innerHTML = "<i class=\"icon icon-angle-right\"></i>";

        return [
            prevBtn,
            nextBtn
        ];
    }

    function buildPagination(){
        //build pagination
        const paginationEl = document.createElement('div');
        paginationEl.classList.add('swiper-pagination');

        return paginationEl;
    }

    function buildFullscreenGallery() {
        const arrOriginals = [];
        const arrThumbs = [];
        const gallery = clickedElement.dataset.gallery;

        if (!gallery) {
            return false;
        }

        let count = 0;
        let lengthGalleries = document.querySelectorAll('fullscreenGallery');

        if (lengthGalleries) {
            count = lengthGalleries.length;
        }

        const id = "fullscreenGallery-" + count;

        let i = 0;

        for (let imagesFullscreen of document.querySelectorAll(selectorImg+"[data-gallery='"+gallery+"']")) {
            let img = imagesFullscreen.querySelector('img');
            let video = imagesFullscreen.querySelector('video');
            let embed = imagesFullscreen.querySelector('iframe');
            let objMedia = {};
            let objMediaThumb = {};
            let isInArray = false;
            let thumb = imagesFullscreen.dataset.thumb;

            if (img) {
                objMedia.type = "image";
                objMedia.src = imagesFullscreen.dataset.original;
                imagesFullscreen.setAttribute('data-gallery-id', id);
                if(thumb === undefined){
                    thumb = img.src;
                }
            }
            if (video) {
                objMedia.type = "video";
                objMedia.src = video.outerHTML;
            }
            if (embed) {
                objMedia.type = "embed";
                objMedia.src = embed.outerHTML;
            }
            for (let insertedOrignal of arrOriginals) {
                if (insertedOrignal.src === objMedia.src) {
                    isInArray = true;
                    break;
                }
            }
            if(thumb){
                objMediaThumb.src = imagesFullscreen.dataset.thumb;
                objMediaThumb.type = "image";
            }
            if (!isInArray) {
                arrOriginals.push(objMedia);
                arrThumbs.push(objMediaThumb);
            }
            i++;
        }

        const fullgallery = document.createElement('div');
        fullgallery.classList.add('fullscreenSwiper');
        fullgallery.classList.add('fullscreenGallery');
        fullgallery.classList.add('is-loading');
        fullgallery.setAttribute('id', id);
        fullgallery.style.opacity = 0;

        //build gallery big images
        const galleryOriginalImages = document.createElement('div');
        galleryOriginalImages.classList.add('fullscreenSwiperImage');

        //add images in big images wrapper
        const swiperWrapperOriginalImages = buildSwiperWrapper(arrOriginals);
        galleryOriginalImages.appendChild(swiperWrapperOriginalImages);
        if(navigation){
            let navigationElements = buildNavigation();
            galleryOriginalImages.appendChild(navigationElements[0]);
            galleryOriginalImages.appendChild(navigationElements[1]);
        }
        if(pagination){
            galleryOriginalImages.appendChild(buildPagination());
        }

        //build gallery thumbs
        /*const galleryThumbs = document.createElement('div');
        galleryThumbs.classList.add('fullscreenSwiperThumbs');
        //add images in thumbs wrapper
        const swiperWrapperThumbs = buildSwiperWrapper(arrThumbs, true);
        galleryThumbs.appendChild(swiperWrapperThumbs);*/

        //build gallery close button
        const galleryCloseButton = document.createElement('div');
        galleryCloseButton.classList.add('fullscreenSwiperClose');
        galleryCloseButton.innerHTML = "<i class=\"icon icon-close\"></i>";

        fullgallery.appendChild(galleryCloseButton);
        fullgallery.appendChild(galleryOriginalImages);
        //fullgallery.appendChild(galleryThumbs);

        document.body.appendChild(fullgallery);

        return id;
    }

    function closeFullscreenGallery(gallery) {
        anime({
            targets: gallery,
            opacity: 0,
            easing: 'linear',
            duration: 400,
            complete: function () {
                gallery.remove();
                document.body.classList.remove('fullscreen-gallery-opened');
            }
        });
    }

    function getCurrentSlideIndex(clickedElement) {
        if (clickedElement.dataset.index) {
            return clickedElement.dataset.index;
        } else {
            return 0;
        }
    }

    function resizeMainSwiper(swiper) {
        swiper.wrapperEl.style.height = swiper.height + "px";
    }

    setTimeout(function () {
        let currentSlide = 0;
        let fullgalleryid = buildFullscreenGallery();
        currentSlide = getCurrentSlideIndex(clickedElement);

        let fullgallery = document.getElementById(fullgalleryid);

        if (fullgallery) {
            Swiper.use([Pagination, Navigation]);
            /*const galleryThumbs = new Swiper('#' + fullgalleryid + '.fullscreenSwiper .fullscreenSwiperThumbs', {
                slidesPerView: 'auto',
                spaceBetween: 10,
                slideToClickedSlide: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                preloadImages: true,
                initialSlide: parseInt(currentSlide),
                navigation: {
                    nextEl: '#' + fullgalleryid + '.fullscreenSwiper .fullscreenSwiperThumbs .swiper-button-next',
                    prevEl: '#' + fullgalleryid + '.fullscreenSwiper .fullscreenSwiperThumbs .swiper-button-prev',
                },
                loop: false,
                breakpoints: {
                    0: {
                        spaceBetween: 5
                    },
                    768: {
                        spaceBetween: 10
                    }
                }
            });*/

            const galleryImage = new Swiper('#' + fullgalleryid + '.fullscreenSwiper .fullscreenSwiperImage', {
                loop: true,
                slidesPerView: 1,
                initialSlide: parseInt(currentSlide),
                preloadImages: true,
                pagination: {
                    el: ".swiper-pagination",
                    type: "fraction",
                },
                navigation: {
                    nextEl: '#' + fullgalleryid + '.fullscreenSwiper .fullscreenSwiperImage .swiper-button-next',
                    prevEl: '#' + fullgalleryid + '.fullscreenSwiper .fullscreenSwiperImage .swiper-button-prev',
                },
                on: {
                    afterInit: function () {
                        fullgallery.style.display = "";
                        anime({
                            targets: fullgallery,
                            opacity: 1,
                            easing: 'linear',
                            duration: 200,
                            complete: function () {
                                resizeMainSwiper(galleryImage);
                                document.body.classList.add('fullscreen-gallery-opened');
                                setTimeout(function () {
                                    fullgallery.classList.remove("is-loading");
                                }, 200);
                            }
                        });
                    },
                }
            });

            fullgallery.querySelector('.fullscreenSwiperClose').addEventListener('click', () => {
                closeFullscreenGallery(fullgallery);
            });
            document.addEventListener("keydown", handleKeyDown);

            function handleKeyDown(e) {
                if (e.key === "Escape") {
                    closeFullscreenGallery(fullgallery);
                }
                if (e.key === "ArrowLeft") {
                    galleryImage.slidePrev(200);
                }
                if (e.key === "ArrowRight") {
                    galleryImage.slideNext(200);
                }
            }
        }
    }, 200);
}