
export function createDescriptionEventListener() {
    for(let el of document.querySelectorAll('.productPageDescription__headline')){
        let wrapper = el.closest('.productPageDescription');
        if(wrapper.classList.contains("isFirst")){
            let content = wrapper.querySelector('.productPageDescription__wrapper');
            if(content){
                content.addEventListener("click", () => {
                    wrapper.classList.toggle("active");
                });
            }
        }else{
            el.addEventListener("click", () => {
                wrapper.classList.toggle("active");
            });
        }
    }
}
