import {loadItemcardGalleryImages} from "./loadItemcardGalleryImages";

export function loadMoreMedia(productPage){
    function showMoreMedia(){
        const grid = productPage.querySelector(".productPageImagesGrid");
        if(grid){
            grid.classList.add("is-active");
            loadItemcardGalleryImages(productPage.querySelectorAll('.productPageImagesGrid__item--more img'));
        }
    }

    const btnShowMoreMedia = productPage.querySelector(".js-showMoreMedia");
    if(btnShowMoreMedia){
        btnShowMoreMedia.addEventListener("click",function (){
            showMoreMedia();
            btnShowMoreMedia.remove();
        });
    }
}